import { Box, Grid, } from "@mui/material";
import { ThemeProvider, styled } from '@mui/material/styles';
import { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { CustomErrorAlert, CustomSuccessAlert } from "../../Reusables/CustomAlert";
import { LoadingDisplay } from "../../Reusables/LoadingDisplay";
import { resetPage } from "../../api/api";
import { theme } from "../../cms/CMS.theme";
import { Header } from "../../components/Header/Header";
import { useEdit } from "../Hooks/useEdit.js";
import { useAuth } from "../auth/useAuth";
import { Save } from '../utils/CommitChanges';
import { useSession } from '../utils/useSession';

//Loading Components
import PublishModal from "./PublishModal.js";
import TopBar from "./TopBar.js";

//Lazy loading components
const AboutAdmin = lazy(()=> import('../Pages/About/About.admin.js'));
const AboutWhoAdmin = lazy(()=> import('../Pages/About/AboutWho.admin.js'));
const AboutWhereAdmin = lazy(()=> import('../Pages/About/AboutWhere.admin.js'));
const MinistryAdmin = lazy(()=> import('../Pages/Ministry/Ministry.admin.js'));
const EventAdmin = lazy(()=> import('../Pages/Event/Event.admin.js'));
const ConnectAdmin = lazy(()=> import('../Pages/Connect/Connect.admin.js'));
const Announcement = lazy(()=> import('../Pages/Announcement/Anouncement.admin.js'));

//Main Styling
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open,toolbarHeight }) => ({
        flexShrink: 1,
        pointerEvents:'none',
        marginTop:`calc(${toolbarHeight}px + 15px)`,
        width: `calc(100% - 325px)`, //"65vw", //pathname.includes('/admin')?  `calc(100% - 325px)`:"65vw",
        marginLeft:`50px`,
       
    }),
);

export function Dashboard (){
    /*** Hooks ***/
    const {
        handleValidation,
        setenablesave,
        enableReset,
        setenablereset,
        setenablepublish,
        setenablerevert,
        setSelected,
        setSelectedItem
    } = useEdit();

    const {
        getSessionStorage,
        addToSessionStorage,
        deleteSessionStorage
    } = useSession();
    
    const { user,logout } = useAuth();

    const drawerWidth = 250;
    const toolbarHeight = 60;
        
    const {pathname} = useLocation();

    const page = useMemo ( ()=>{
     return ((pathname == '/admin')? 'home': pathname.substring(7))},[pathname]);

    const authenticated = useMemo ( ()=>{return sessionStorage.getItem('ready')},[]);
    
    const [commitResponse,setCommitResponse] = useState("");
    const [openPublish,setOpenPublish] = useState(false);

    /** Dashboard data loading */
    useEffect(()=>{
        console.debug("Use Effect Dashboard auth: ",authenticated);
        if(authenticated){
            setCommitResponse("");
          
            let sessionStore = JSON.parse(sessionStorage.getItem('changes'));
            //Set enable save if there are changes in session storage
            if(sessionStore){
                setenablerevert(true);
                setenablereset(true);
                setenablesave(true);
            }else{
                setenablesave(false);
            }        
        }
    },[authenticated]);

    useEffect(()=>{
       
        let sessionStore = JSON.parse(sessionStorage.getItem('changes'));
        //Set enable save if there are changes in session storage
        if(sessionStore){
            setenablerevert(true);
            setenablereset(true);
            setenablesave(true);
        }else{
            setenablesave(false);
        }        
    });

    /*** HANDLERS ***/
    const handleLogout = () => {
        logout();
    };
    
    //Handle when save button is pressed
    const handleSave = useCallback((e)=>{
        e.preventDefault();
        setCommitResponse({status:'processing',message:""});
        //Call save function
        Save({getSessionStorage,addToSessionStorage,deleteSessionStorage})
        .then(response=>{
            if(response.status == 400){
                console.error('Error saving - ', response.message);
                let missing = [];
                let missingFields = response.message.map(data=>{
                    return data.reason.error.message.map(item=>{
                       return  (`${item.path}: ${item.msg}`); 
                    });
                }).join(', ');
                setCommitResponse({
                    status:'error',
                    message:`Error encountered while attempting to save. \nError: " 
                    ${missingFields} "`
                });
                setTimeout(()=>{
                    setCommitResponse("");
                    setenablesave(true);
                },3000);
            }else{
                setCommitResponse({status:'success',message:"Saved."});
                setTimeout(()=>{
                    setenablesave(false);
                    setenablepublish(true);
                    setCommitResponse("");
                    setSelected(false);
                    setSelectedItem(false);
                },3000);
            }
        }).catch((error)=>{
            console.error('Error saving - ',error);
            if(error.code == 400){
                let missingFields = error.message.map(data=>data.path).join(', ')
                setCommitResponse({
                    status:'error',
                    message:"Unable to save. You are missing the following required fields: " 
                    + " \"" + 
                    missingFields + "\""
                });
            }else{
                console.error('Error saving: ', error)    
                setCommitResponse({status:'error',message:"There was an issue saving changes, please try again."});
            }
            setTimeout(()=>{
                // setenablepublish(true);
                setenablesave(true);
                setCommitResponse("");
               
            },3000)
        });

    },[]);

    //Revert just removes all session storage, so reverts none saved changes.
    const handleRevert = () =>{
        setCommitResponse({status:'processing',message:""});
        //TODO: replace with remove session function
        setTimeout(()=>{
            sessionStorage.removeItem('changes');
            setSelected(false);
            setSelectedItem(false);
            if(JSON.parse(sessionStorage.getItem('changes'))){
                console.error('Failed to revert changes: ');
                setCommitResponse({status:'error',message:" There was an issue trying to revert changes."});
                setenablerevert(true);
                setTimeout(()=>{
                    setCommitResponse("");
                },2000);
            }else{
                setCommitResponse({status:'success',message:"Changes have been undone."});
                setenablesave(false);
                setenablerevert(false);
                setTimeout(()=>{
                    setCommitResponse("");

                },2000)
            }
        },500);
    }

     //Handle when reset button is pressed
     //This just resets one page/collection
     //Copy current web version to the admin version.
     //any saved changes that arent published are reset.
    const handleReset =(e)=>{
        //Delete all session changes and disable reset and save buttons   
        let copypage = page.replace('/',"");
        setCommitResponse({status:'processing',message:""});
        resetPage(copypage).then(resp=>{
            console.debug('reset response: ',resp);
            if(resp.error){
                console.error('Failed to reset changes: ',e);
                setCommitResponse({status:'error',message:" There was an issue trying to reset changes, please try again."});
                setTimeout(()=>{
                    setCommitResponse("");
                },2000);
            }else{
                //Remove the reset items from session storage
                let sessionStore = JSON.parse(sessionStorage.getItem('changes'));
                if(sessionStore){
                    let sesh = sessionStore.slice();
                    let items = sesh.filter(el=> {
                        return el.key.includes(copypage)||el.value.component.includes(copypage)});
                    items.map(
                        (item)=>{
                            sesh.splice(sesh.indexOf(item),1);
                        }
                    )
                    if(sesh.length>0){
                        sessionStore = sesh;
                    }else{
                        //TODO: replace with remove function
                        sessionStorage.removeItem('changes');
                    }
                }
                //Remove the reset items from local storage
                console.debug('Successfully reset page: ',resp);
                setCommitResponse({status:'success',message:"Page has been reset."});
                setenablesave(false);
                setenablereset(false);
                setTimeout(()=>{
                    setCommitResponse("");
                    //TODO:refresh page if necessary
                    // window.location.reload();
                },2000);
            }
            //After reset, remove changes from session and local storage.
        }).catch((e)=>{
            console.error('Failed to reset changes: ',e);
            setCommitResponse({status:'error',message:" There was an issue trying to reset changes, please try again."});
            setTimeout(()=>{
                setCommitResponse("");
            },2000);
        });
    }
    return(    
        <ThemeProvider theme={theme}>
            <Box 
                sx={{ display: 'flex' }}
            >
                <Grid 
                    sx={{
                        // border:'2px dashed orange',
                        display:'flex',
                        flexDirection:'column',
                        width:'100%',
                    }}
                >
                    <TopBar 
                        pathname={pathname} 
                        toolbarHeight={toolbarHeight} 
                        drawerWidth={drawerWidth}
                        handleReset={handleReset}
                        handleSave={handleSave}
                        setOpenPublish={setOpenPublish}
                        handleLogout={handleLogout}
                        handleRevert={handleRevert}
                    />

                    {authenticated && 
                    <Main  toolbarHeight={toolbarHeight}>      
                        {openPublish && <PublishModal 
                            openPublish={openPublish}
                            setOpenPublish={setOpenPublish}
                            setCommitResponse={setCommitResponse}
                            />     }
                        
                        <Header/>
                        {
                            commitResponse.status == "success" ?
                                <CustomSuccessAlert message={commitResponse.message} onClose={()=>setCommitResponse("")} />
                                :
                            commitResponse.status == "error"?
                                <CustomErrorAlert message={commitResponse.message} onClose={()=>setCommitResponse("")} />
                            :
                            <></>
                        }       
                        {
                            commitResponse.status == 'processing'?
                                <LoadingDisplay />
                            // :
                            // (pathname == '/admin')?
                    
                            //     <HomeAdmin 
                            //         handleSave={handleSave}
                            //         drawerWidth={drawerWidth}
                            //         setenablesave={setenablesave}
                            //     />
                            :
                            <Grid>
                              
                               
                                {((pathname == '/admin') || (pathname == ('/admin/about')))
                                &&
                                <Suspense fallback= {<LoadingDisplay />} >
                                    <AboutAdmin 
                                        drawerWidth={drawerWidth}
                                        handleSave={handleSave}
                                        handleValidation={handleValidation}
                                        />
                                </Suspense>}
                                
                                {(pathname == ('/admin/about/who'))
                                &&
                                 <Suspense fallback= {<LoadingDisplay />} >
                                    <AboutWhoAdmin 
                                        drawerWidth={drawerWidth}
                                        handleSave={handleSave}
                                        handleValidation={handleValidation}
                                    />
                                </Suspense> }
                                
                                {(pathname == ('/admin/about/where'))
                                &&
                                <Suspense fallback= {<LoadingDisplay />} >
                                    <AboutWhereAdmin 
                                        drawerWidth={drawerWidth}
                                        handleSave={handleSave}
                                        handleValidation={handleValidation}
                                    />
                                </Suspense>}
                                
                                {(pathname == ('/admin/ministry'))
                                &&
                                <Suspense fallback= {<LoadingDisplay />} >
                                    <MinistryAdmin 
                                        drawerWidth={drawerWidth}
                                        handleSave={handleSave}
                                        handleValidation={handleValidation}
                                    />
                                </Suspense>}

                                {(pathname == ('/admin/event'))
                                &&
                                <Suspense fallback= {<LoadingDisplay />}>
                                    <EventAdmin 
                                        drawerWidth={drawerWidth}
                                        handleSave={handleSave}
                                        handleValidation={handleValidation}
                                    />
                                </Suspense>}

                                {(pathname == ('/admin/connect'))
                                &&
                                <Suspense fallback= {<LoadingDisplay />}>
                                <ConnectAdmin 
                                    drawerWidth={drawerWidth}
                                    handleSave={handleSave}
                                    handleValidation={handleValidation}
                                />
                                </Suspense>}
                                {(pathname == ('/admin/announcement'))
                                &&
                                <Suspense fallback= {<LoadingDisplay />}>
                                <Announcement 
                                    drawerWidth={drawerWidth}
                                    handleSave={handleSave}
                 
                                />
                                </Suspense>}
                            </Grid>
                        }
                    </Main>
                    }
                </Grid>
            </Box>
        </ThemeProvider>
    )

}
